.main {
  flex: 1;
  background-color: white;
  color: #342d28;
  min-height: 80vh;
  min-width: 100vw;
  padding: 10px, 20px;
}

.temp-img {
  width: 600px;
  height: 400px;
  object-fit: cover;
}
