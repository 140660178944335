.navbar {
  display: flex;
  align-items: center;
  width: 80vw;
  height: 70px;
  padding: 0;
  margin: 0;
  padding: 10px 20px;
  background-color: #83b271;
}

.logo-section {
  flex: 0 0 30%;
  display: flex;
  align-items: center;
}

.logo {
  max-width: 100%; /* Adjust size as needed */
  width: 3rem;
  border-radius: 50%;
  padding: 0 10px;
}

.nav-links {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 20px;
  font-weight: bold;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-weight: 500;
}
